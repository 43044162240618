const Images = {
    DLogo: require('./logo.png'),
    Photo: require('./Photo.png'),
    // FooterBg: require('./Footer-bg.png'),
    // PurpleBlur: require('./purpleblur.png'),
    // Ray1: require('./ray1.png'),
    // Ray2: require('./ray2.png'),
    // Ray3: require('./ray3.png'),
    // BhanuImg: require('./Primary.png'),
    // Freelansters: require('./Freelansters.png'),
    // JellyfishRetro: require('./Jellyfish-x-retro.png'),
    // Venue: require('./Venue.png'),
    // Hotel: require('./Hotel.png'),
    // Sax: require('./Sax.png'),
    // Resume: require('./w_Web-Developer.pdf'),
    // NextNpm: require('./NextNpm.png')
}

export default Images;